export enum PaymentMethod {
  None = 'None',
  Stripe = 'Stripe',
  Invoice = 'Invoice',
  PreClinical = 'PreClinical',
  PayPal = 'PayPal',
  Square = 'Square',
  Check = 'Check',
  Cash = 'Cash',
  Giftcard = 'Giftcard',
  Complimentary = 'Complimentary',
  ActivationCode = 'ActivationCode',
  Jotform = 'Jotform',
  Coupon = 'Coupon',
}

export enum PaymentMethodAdditional {
  PayPalCard = 'PayPalCard',
}
