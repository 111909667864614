import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {Divider, FieldData, FieldName, SectionName} from 'BusinessPortal/components/common/styled';
import {IAppState} from 'Common/store/IAppState';
import {actions, BusinessPortalOrderModule, selectors} from 'BusinessPortal/store/order';
import {connect, ConnectedProps} from 'react-redux';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Loading from 'Loading/components/Loading';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {OrderStatusList} from 'Admin/AdminDashboard/components/shared';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {mapReportStatusToLabel, OrderReportStatus} from 'Common/constants/OrderReportStatus';
import {OrderReportType} from 'Common/constants/OrderReportType';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {downloadByUrl} from 'Common/helpers/downloadHelper';
import {getFileName} from 'Common/helpers/getFileName';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {breakpoints} from 'Common/constants/Breakpoints';

const filteredOrderStatuses: OrderStatus[] = [
  OrderStatus.orderPlaced,
  OrderStatus.paymentReceived,
  OrderStatus.sampleReceived,
  OrderStatus.releaseReady,
];

const Root = styled.div`
  padding: 4px;

  @media ${breakpoints.sm} {
    padding: 16px;
  }
`;

const SectionContent = styled.div`
  padding: 0 8px;
`;

const Header = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black0};
`;

const FieldWrapper = styled.div`
  padding: 8px 0;
`;

const FieldDataWrapper = styled(FieldData)`
  margin-right: 0;
`;

const ReportName = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${ColorPalette.black0};
  margin-right: 16px;
`;

const ReportStatus = styled.div<{status: OrderReportStatus}>`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  color: ${({status}) => (status === OrderReportStatus.Processing ? ColorPalette.gray56 : ColorPalette.gray44)};
`;

const PackagesTitle = styled(FieldName)`
  font-size: ${Typography.size.size18};
`;

const PackagesPrice = styled(FieldName)`
  font-size: ${Typography.size.size22};
  margin-right: 0;
`;

const CouponTitle = styled(FieldName)`
  font-size: ${Typography.size.size14};
  color: ${ColorPalette.gray56};
`;

const CouponPrice = styled(FieldName)`
  font-size: ${Typography.size.size16};
  color: ${ColorPalette.gray56};
  margin-right: 0;
`;

const OrderStatusListWrapper = styled.div`
  margin: -24px 0 0 -24px;
`;

const DownloadReportButton = styled(ColoredIcon)`
  cursor: pointer;
`;

const PayNowButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

interface IProps {
  orderId: number;
  openPaymentPage(id: number): void;
  downloadSubmissionForm(id: number): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IProps & IConnected;

const OrderForm = (props: AllProps) => {
  const {
    orderId,
    getOrderDetails,
    getOrderPackagesAndTests,
    getOrderReports,
    orderDetails,
    orderPackagesDetails,
    orderReports,
    orderDetailsLoading,
    orderPackagesDetailsLoading,
    orderReportsLoading,
    resetOrderDetails,
    downloadSubmissionForm,
    openPaymentPage,
    getOrderStatuses,
    orderStatuses,
    orderStatusesLoading,
  } = props;

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId);
      getOrderPackagesAndTests(orderId);
      getOrderReports(orderId);
      getOrderStatuses(orderId);
    }

    return resetOrderDetails;
  }, [getOrderDetails, getOrderPackagesAndTests, getOrderReports, getOrderStatuses, orderId, resetOrderDetails]);

  const navigate = useNavigate();

  const isLoading = [orderDetailsLoading, orderPackagesDetailsLoading, orderReportsLoading, orderStatusesLoading].some(
    (i) => i.isRequesting
  );

  const payNowHandler = useCallback(() => openPaymentPage(orderId), [openPaymentPage, orderId]);
  const submissionFormHandler = useCallback(() => downloadSubmissionForm(orderId), [downloadSubmissionForm, orderId]);

  const redirectToOnlineReport = useCallback(
    (isAncestry?: boolean) => {
      navigate(`/business-portal/online-report/${orderDetails?.horse.id}/0/${isAncestry ? 'ancestry' : ''}`);
    },
    [navigate, orderDetails?.horse.id]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!orderDetails) {
    return null;
  }

  if (!orderStatuses) {
    return null;
  }

  const {id, sample, horse} = orderDetails;
  const {status, hasSample} = orderStatuses;

  const mainButtons: Record<OrderStatus, {title: string; handler(): void}> = {
    [OrderStatus.orderPlaced]: {title: 'Pay now', handler: payNowHandler},
    [OrderStatus.paymentReceived]: {title: 'Submission form', handler: submissionFormHandler},
    [OrderStatus.sampleReceived]: {title: 'Submission form', handler: submissionFormHandler},
    [OrderStatus.resultsReady]: {title: 'Submission form', handler: submissionFormHandler},
    [OrderStatus.reviewReady]: {title: 'Submission form', handler: submissionFormHandler},
    [OrderStatus.releaseReady]: {title: 'Open report', handler: () => redirectToOnlineReport()},
  };

  return (
    <Root>
      <div className="d-flex flex-wrap justify-content-between">
        <Header>Order #{id}</Header>
        <PrimaryButton size="small" onClick={mainButtons[status].handler}>
          {mainButtons[status].title}
        </PrimaryButton>
      </div>

      <SectionName>Horse info</SectionName>
      <SectionContent>
        <MutableFieldsGroup>
          <FieldWrapper className="d-flex">
            <FieldName width="100px">Name:</FieldName>
            <FieldDataWrapper>{horse.name}</FieldDataWrapper>
          </FieldWrapper>
          <FieldWrapper className="d-flex">
            <FieldName width="100px">Date of birth:</FieldName>
            <FieldDataWrapper>{horse.dateOfBirth}</FieldDataWrapper>
          </FieldWrapper>
        </MutableFieldsGroup>
        <FieldWrapper className="d-flex">
          <FieldName width="100px">Sex:</FieldName>
          <FieldDataWrapper>{horse.gender}</FieldDataWrapper>
        </FieldWrapper>
      </SectionContent>

      {orderReports.length > 0 && (
        <>
          <SectionName>Reports</SectionName>
          <SectionContent>
            {orderReports.map((report, i) => {
              const downloadReport = () => {
                const fileExtension = '.pdf';
                const reportName = report.url && getFileName(report.url, fileExtension);
                report.url && reportName && downloadByUrl(report.url, reportName, fileExtension);
              };

              const isDiagnosticComplete =
                report.type === OrderReportType.Diagnostic && report.status === OrderReportStatus.Complete;
              const isAncestryComplete =
                report.type === OrderReportType.Ancestry && report.status === OrderReportStatus.Complete;

              return (
                <div key={i} className="d-flex flex-column justify-content-center">
                  <FieldWrapper className="d-flex justify-content-between align-items-baseline">
                    <ReportName>{report.type}</ReportName>
                    {isDiagnosticComplete && (
                      <PrimaryButton size="small" onClick={() => redirectToOnlineReport()}>
                        Open Report
                      </PrimaryButton>
                    )}
                    {isAncestryComplete && (
                      <PrimaryButton size="small" onClick={() => redirectToOnlineReport(true)}>
                        Open Report
                      </PrimaryButton>
                    )}
                    {report.type !== OrderReportType.Diagnostic &&
                      report.type !== OrderReportType.Ancestry &&
                      report.status === OrderReportStatus.Complete && (
                        <DownloadReportButton name={IconName.Upload} onClick={downloadReport} />
                      )}
                    {report.status !== OrderReportStatus.Complete && (
                      <ReportStatus className="d-flex justify-content-end" status={report.status}>
                        {mapReportStatusToLabel[report.status]}
                      </ReportStatus>
                    )}
                  </FieldWrapper>
                  {i < orderReports.length - 1 && <Divider className="align-self-center" width="97%" />}
                </div>
              );
            })}
          </SectionContent>
        </>
      )}

      <SectionName>Status</SectionName>
      <SectionContent>
        <FieldWrapper>
          <OrderStatusListWrapper>
            <OrderStatusList
              dividerHeight={23}
              textStyle={{fontSize: 16, fontFamily: Theme.font.primary}}
              status={status}
              completedStatuses={hasSample ? OrderStatus.sampleReceived : undefined}
              filteredStatuses={filteredOrderStatuses}
            />
          </OrderStatusListWrapper>
        </FieldWrapper>
      </SectionContent>

      {orderPackagesDetails && (
        <>
          <SectionName>Packages Details</SectionName>
          <SectionContent>
            {orderPackagesDetails.packagesAndTests.map((item, i) => (
              <FieldWrapper className="d-flex justify-content-between" key={i}>
                <FieldName>{item.name}</FieldName>
                <FieldDataWrapper className="d-flex justify-content-end">${item.price}</FieldDataWrapper>
              </FieldWrapper>
            ))}
            {orderPackagesDetails.discount > 0 && (
              <FieldWrapper className="d-flex justify-content-between">
                <CouponTitle width="130px">Coupon Discount</CouponTitle>
                <CouponPrice className="d-flex justify-content-end">-${orderPackagesDetails?.discount}</CouponPrice>
              </FieldWrapper>
            )}
            <FieldWrapper className="d-flex justify-content-between">
              <PackagesTitle width="130px">Packages total</PackagesTitle>
              <PackagesPrice className="d-flex justify-content-end">${orderPackagesDetails.amount}</PackagesPrice>
            </FieldWrapper>
            {status === OrderStatus.orderPlaced && (
              <PayNowButton className="w-100" onClick={payNowHandler}>
                Pay now
              </PayNowButton>
            )}
          </SectionContent>
        </>
      )}

      <SectionName>Sample</SectionName>
      <SectionContent>
        <FieldWrapper className="d-flex justify-content-between">
          <FieldName>Sample Id:</FieldName>
          <FieldDataWrapper className="d-flex justify-content-end">{sample.sampleId || '-'}</FieldDataWrapper>
        </FieldWrapper>
        <FieldWrapper className="d-flex justify-content-between">
          <FieldName width="123px">Sample Received:</FieldName>
          <FieldDataWrapper className="d-flex justify-content-end">{sample.receivedDate || '-'}</FieldDataWrapper>
        </FieldWrapper>
      </SectionContent>

      <PrimaryButton className="w-100" variant="outlined" onClick={submissionFormHandler}>
        Submission form
      </PrimaryButton>
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  orderDetails: selectors.selectOrderDetails(state),
  orderPackagesDetails: selectors.selectOrderPackagesAndTests(state),
  orderReports: selectors.selectOrderReports(state),
  orderDetailsLoading: selectors.selectCommunication(state, 'orderDetailsLoading'),
  orderPackagesDetailsLoading: selectors.selectCommunication(state, 'orderPackagesDetailsLoading'),
  orderReportsLoading: selectors.selectCommunication(state, 'orderReportsLoading'),
  orderStatuses: selectors.selectOrderStatuses(state),
  orderStatusesLoading: selectors.selectCommunication(state, 'orderStatusesLoading'),
});

const mapDispatchToProps = {
  getOrderDetails: actions.getOrderDetails,
  getOrderPackagesAndTests: actions.getOrderPackagesDetails,
  getOrderReports: actions.getOrderReports,
  getOrderStatuses: actions.getOrderStatuses,
  resetOrderDetails: actions.resetOrderDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withDynamicModules(connector(OrderForm), [BusinessPortalOrderModule]);
export type OrderFormProps = IProps;
