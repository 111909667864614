import {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import {ReactComponent as PdfTipIcon} from 'OnlineReportPrintable/img/pdf_tip.svg';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {setOnlineReportTypeToStorage} from 'OnlineReport/helpers/setOnlineReportTypeToStorage';
import OnePagerDiagnostics from 'OnlineReport/components/Diagnostics/OnePagerDiagnostics';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Root = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 60px;
  @media print {
    padding: 0 60px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div<{heightPercent?: number}>`
  padding: 0 ${({heightPercent}) => heightPercent}%;
`;

const PrintButton = styled.div`
  position: fixed;
  right: 60px;
  z-index: 1;

  @media print {
    display: none !important;
  }
`;

const SaveTip = styled.div`
  width: 168px;
  height: 158px;
  background: ${ColorPalette.white0};
  box-shadow: 0px 6px 14px ${hexToRgba(ColorPalette.black0, 0.05)}, 0px 0px 4px ${hexToRgba(ColorPalette.black0, 0.02)};
  border-radius: 4px;
  margin-top: 18px;
`;

const TipIcon = styled(PdfTipIcon)`
  margin: 12px;
`;

const TipText = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0 12px;
`;

type RouteParams = {horseId: string; orderId: string; reportType: OnlineReportType};

const DiagnosticsReportPrintable = () => {
  const {horseId, orderId, reportType = OnlineReportType.User} = useParams<RouteParams>() as RouteParams;
  const {isMobile, isTablet, isLargeDesktop} = useMediaQuery();
  const isMobileView = isMobile || isTablet;
  const [contentHeightPercent, setContentHeightPercent] = useState<number>(isLargeDesktop ? 25 : isMobileView ? 2 : 10);
  const [shouldTriggerPrint, setShouldTriggerPrint] = useState(false);

  useEffect(() => {
    setOnlineReportTypeToStorage(reportType);
  }, [reportType]);

  const openPrintWindow = () => {
    setShouldTriggerPrint(true);
    setTimeout(() => {
      setShouldTriggerPrint(false);
    }, 100);
  };

  return (
    <BaseLayout
      hideFooter={true}
      hideHeader={true}
      withoutPaddings={true}
      widthByContent={false}
      backButtonStyle={{marginLeft: 62, marginTop: 10}}
    >
      <Root>
        <Container>
          {isLargeDesktop && (
            <PrintButton className="d-flex flex-column justify-content-end">
              <PrimaryButton size="small" onClick={() => openPrintWindow()}>
                Print
              </PrimaryButton>
              <SaveTip className="d-flex flex-column">
                <TipIcon className="align-self-center" />
                <TipText>
                  To save a copy to your device, click <strong>"Print"</strong> and choose{' '}
                  <strong>"Save as PDF"</strong> from your printer menu
                </TipText>
              </SaveTip>
            </PrintButton>
          )}

          <Content className="w-100 h-100" heightPercent={contentHeightPercent}>
            <OnePagerDiagnostics
              isPrintable={true}
              horseId={horseId}
              orderId={orderId}
              reportType={reportType}
              onHasError={() => false}
              shouldTriggerPrint={shouldTriggerPrint}
            />
          </Content>
        </Container>
      </Root>
    </BaseLayout>
  );
};

export default memo(DiagnosticsReportPrintable);
