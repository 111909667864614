import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import DogTestResultsUploadForm from '../../../components/DogTestResultsUploadForm/DogTestResultsUploadForm';

interface IUpdateModalParams {
  onSuccess?(): void;
}

export function useDogTestResultsUploadModal(params: IUpdateModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);
  const [dogId, setDogId] = useState('');
  const openDogTestResultsUploadModal = useCallback((id: string) => {
    setIsOpen(true);
    setDogId(id);
  }, []);
  const closeDogTestResultsUploadModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeDogTestResultsUploadModal();
    onSuccess && onSuccess();
  }, [closeDogTestResultsUploadModal, onSuccess]);

  const dogTestResultsUploadModal = (
    <ModalWindow isOpen={isOpen} onClose={closeDogTestResultsUploadModal}>
      <DogTestResultsUploadForm onSuccess={handleSuccess} dogId={dogId} />
    </ModalWindow>
  );

  return {
    dogTestResultsUploadModal,
    openDogTestResultsUploadModal,
  };
}
