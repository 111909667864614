import {action} from 'typesafe-actions';
import {IAppState} from 'Common/store/IAppState';

import {ActionResult} from 'Common/store/store';
import {
  createActivationCodesActions,
  createActivationCodesActionTypes,
  deactivateActivationCodeActions,
  deactivateActivationCodeActionTypes,
  getActivationCodeByIdActions,
  getActivationCodeByIdActionTypes,
  getActivationCodesActions,
  getActivationCodesActionTypes,
  resetActivationCodeDetailsActions,
  resetActivationCodeDetailsActionTypes,
  updateActivationCodeActions,
  updateActivationCodeActionTypes,
} from 'Admin/AdminDashboard/store/adminActivationCodes/types';
import {IRequestParams} from 'Common/models/IRequestParams';
import ActivationCodeDataService from 'Admin/AdminDashboard/services/ActivationCodeDataService';
import {IActivationCodeRequest} from 'Admin/AdminDashboard/components/ActivationCodes/ActivationCodeForm/validation';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getActivationCodes =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getActivationCodesActions> =>
  async (dispatch) => {
    dispatch(action(getActivationCodesActionTypes.REQUEST));
    try {
      const res = await ActivationCodeDataService.getActivationCodes(params);
      dispatch(action(getActivationCodesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getActivationCodesActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getActivationCodeById =
  (activationCodeId: number): ActionResult<IAppState, void, getActivationCodeByIdActions> =>
  async (dispatch) => {
    dispatch(action(getActivationCodeByIdActionTypes.REQUEST));
    try {
      const result = await ActivationCodeDataService.getActivationCode(activationCodeId);
      console.log(`Received data for ${activationCodeId}`);
      console.log(result);
      dispatch(action(getActivationCodeByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getActivationCodeByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createActivationCodes =
  (params: IActivationCodeRequest): ActionResult<IAppState, void, createActivationCodesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createActivationCodesActionTypes.REQUEST));
      console.log(`Created`);
      console.log(params);
      await ActivationCodeDataService.createActivationCodes(params);
      dispatch(action(createActivationCodesActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createActivationCodesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateActivationCode =
  (params: IActivationCodeRequest): ActionResult<IAppState, void, updateActivationCodeActions> =>
  async (dispatch) => {
    try {
      console.log(`Update params`);
      console.log(params);
      dispatch(action(updateActivationCodeActionTypes.REQUEST));
      await ActivationCodeDataService.updateActivationCode(params);
      dispatch(action(updateActivationCodeActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateActivationCodeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deactivateActivationCode =
  (id: number): ActionResult<IAppState, void, deactivateActivationCodeActions> =>
  async (dispatch) => {
    dispatch(action(deactivateActivationCodeActionTypes.REQUEST));
    try {
      await ActivationCodeDataService.deactivateActivationCode(id);
      dispatch(action(deactivateActivationCodeActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deactivateActivationCodeActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetActivationCodeDetailResults =
  (): ActionResult<IAppState, void, resetActivationCodeDetailsActions> => async (dispatch) => {
    dispatch(action(resetActivationCodeDetailsActionTypes.RESET));
  };
