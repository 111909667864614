import {HorseSampleStatus, IOrderStatusFields, OrderStatusField} from 'Order/models/IOrderStatusFields';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {IOrderStatused} from 'Common/models/IOrderStatused';

export function parseOrderStatus(statuses: IOrderStatusFields): IOrderStatused {
  const {sampleStatus, paymentStatus, releaseStatus, resultsStatus} = statuses;
  const currentStatus = getCurrentStatus(statuses);

  return {
    status: currentStatus,
    hasPayment: paymentStatus === OrderStatusField.Ready,
    hasSample: sampleStatus === HorseSampleStatus.Received,
    hasRelease: releaseStatus === OrderStatusField.Ready,
    hasNotResults: resultsStatus === OrderStatusField.Waiting,
  };
}

function getCurrentStatus({
  paymentStatus,
  sampleStatus,
  reviewStatus,
  resultsStatus,
  releaseStatus,
}: IOrderStatusFields) {
  if (paymentStatus === OrderStatusField.Waiting) {
    return OrderStatus.orderPlaced;
  }
  if (sampleStatus === HorseSampleStatus.Waiting) {
    if (releaseStatus === OrderStatusField.Waiting) {
      return OrderStatus.paymentReceived;
    } else {
      return OrderStatus.releaseReady;
    }
  }
  if (reviewStatus === OrderStatusField.Waiting) {
    if (resultsStatus === OrderStatusField.Waiting) {
      return OrderStatus.sampleReceived;
    } else {
      return OrderStatus.resultsReady;
    }
  }
  if (releaseStatus === OrderStatusField.Waiting) {
    return OrderStatus.reviewReady;
  }

  return OrderStatus.releaseReady;
}
