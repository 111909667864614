import React, {useCallback} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {IDog} from 'Dogs/models/IDog';
import {FaFile} from 'react-icons/fa';
import {AdminDogsModule} from 'Admin/AdminDashboard/store/adminDogs/adminDogsModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminDogs/index';
import {Pagination} from 'Common/components/Controls/index';
import Loading from 'Loading/components/Loading';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import PrimaryButton from '../../../../Common/components/Controls/Buttons/PrimaryButton';
import {useDogTestResultsUploadModal} from '../../../shared/helpers/hooks/useDogActions/useDogTestResultsUploadModal';
import {IDogAdmin} from '../../models/Dog/IDogAdmin';
import {AvatarCell} from '../shared';
import {USER_SUBSCRIPTION_MARKED_ICON} from '../../../../Common/constants/avatarMarkedIcon';

import {CellAlign} from '../../../../Common/components/Table/constants/CellAlign';
import Actions from '../../../../Common/components/Actions/Actions';
import {IMenuItemValued} from '../../../../Common/models/IMenuItemValued';
import {useDogEditModal} from '../../../shared/helpers/hooks/useDogActions/useDogEditModal';
import {useDogCreateModal} from '../../../shared/helpers/hooks/useDogActions/useDogCreateModal';

enum DogsActionValue {
  UploadReport = 'uploadReport',
  EditDog = 'editDog',
}

const menuItems: IMenuItemValued<DogsActionValue>[] = [
  {value: DogsActionValue.UploadReport, label: 'Upload Report'},
  {value: DogsActionValue.EditDog, label: 'Edit Dog'},
];

const AddButton = styled(PrimaryButton)`
  margin-left: 24px;
`;

const DogsTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const Dogs = (props: AllProps) => {
  const {getDogs, dogsLoading, dogs} = props;

  const {params, changeSorting, sorting, handleCountPerPage, handlePageSelect, searchBar} =
    useCommonAdminPageData<IDog>({
      getItems: getDogs,
      searchBarPlaceholder: 'Search for dogs by any keyword',
    });

  const reloadDogs = useCallback(() => {
    getDogs(params);
  }, [getDogs, params]);

  const {dogTestResultsUploadModal, openDogTestResultsUploadModal} = useDogTestResultsUploadModal({
    onSuccess: reloadDogs,
  });

  const {dogEditModal, openDogEditModal} = useDogEditModal({
    onSuccess: reloadDogs,
  });

  const {dogCreateModal, openDogCreateModal} = useDogCreateModal({
    onSuccess: reloadDogs,
  });

  const isRequesting = [dogsLoading].some((i) => i.isRequesting);

  const isShowPagination = dogs.length > 0;

  return (
    <div>
      {dogTestResultsUploadModal}
      {dogEditModal}
      {dogCreateModal}
      <AdminPageLayout>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">{searchBar}</div>
          <AddButton onClick={() => openDogCreateModal()}>+ Create Dog</AddButton>
        </div>

        <DogsTable className="position-relative">
          {isRequesting && <Loading />}
          <Table<IDog> data={dogs} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
            <Cell<IDog> header="ID" dataKey="id" render={({id}) => `#${id}`} width="10%" />
            <Cell<IDog> header="Name" dataKey="name" render={({name}) => name} width="20%" />
            <Cell<IDog> header="Sample ID" dataKey="sampleId" render={({sampleId}) => sampleId} width="10%" />
            <Cell<IDogAdmin>
              header="Owner"
              dataKey="owner"
              render={({owner}) => (
                <AvatarCell
                  type="owner"
                  label={owner.name}
                  avatarUrl={owner.avatar?.url}
                  profileUrl={`/admin/user/user-profile/${owner.id}/horses`}
                  isMarkedIcon={owner.hasSubscription}
                  markedIcon={USER_SUBSCRIPTION_MARKED_ICON}
                  hasOnlineReportAccess={owner.hasOnlineReportAccess}
                />
              )}
              width="25%"
            />
            <Cell<IDog>
              header="Report"
              dataKey="diagnosticsReportUrl"
              render={({diagnosticsReportUrl}) => {
                return diagnosticsReportUrl?.length > 0 && <FaFile style={{color: 'green'}} />;
              }}
              width="10%"
            />

            <Cell<IDog>
              header="Actions"
              align={CellAlign.Right}
              width="10%"
              render={(dog) => (
                <Actions<DogsActionValue>
                  menuItems={menuItems}
                  uploadReport={() => openDogTestResultsUploadModal(dog.id)}
                  editDog={() => openDogEditModal(dog)}
                />
              )}
            />
          </Table>
          {isShowPagination && (
            <Pagination
              pagination={props.pagination}
              onPageSelect={handlePageSelect}
              onChangeCountPerPage={handleCountPerPage}
            />
          )}
        </DogsTable>
      </AdminPageLayout>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  dogs: selectors.selectDogs(state),
  dogsLoading: selectors.selectCommunication(state, 'dogsLoading'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getDogs: actions.getDogs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(Dogs);

export default withDynamicModules(Connected, [AdminDogsModule]);
