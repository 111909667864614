import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import * as R from 'ramda';

import {breakpoints, size} from 'Common/constants/Breakpoints';
import HorseOverview from 'UserProfile/components/TabsData/common/HorseOverview';
import {IPotentialPartner} from 'FindHorse/models/PotentialPartners';

import ColorPalette from 'Common/constants/ColorPalette';
import {dictionaryToString, StringDictionaryErrorType} from 'Common/helpers/dictionaryToString';

import Characteristics from './Characteristics';
import FavoriteHorseButtons from 'FavoriteHorse/components/FavoriteHorseButtons';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import {Gender} from 'Common/constants/Gender';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;

  @media ${breakpoints.md} {
    box-shadow: none;
    border-radius: none;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: minmax(210px, 300px) minmax(auto, 400px) 176px;
    justify-content: space-between;
    padding: 24px 16px 24px 24px;
  }
`;

const ProfileButton = styled(PrimaryButton)`
  margin-bottom: 16px;
`;

const CharacteristicsWrapper = styled.div`
  flex-grow: 1;
  @media (max-width: ${size.md}px) {
    padding: 14px 0 22px;
    margin-top: 8px;
    border-top: 1px solid ${ColorPalette.white9};
    border-bottom: 1px solid ${ColorPalette.white9};
  }
`;

const ButtonsWrapper = styled.div`
  margin-left: 5px;

  @media ${breakpoints.md} {
    width: 175px;
  }
`;

interface IExternalProps {
  horse: IPotentialPartner;
}

type AllProps = IExternalProps & IWithFavoriteHorseActionsProps;

function PotentialPartner(props: AllProps) {
  const {horse, addFavoriteHorse, deleteFavoriteHorse} = props;
  const {
    name,
    gender,
    dateOfBirth,
    abilities,
    avatar,
    commercialTypes,
    colors,
    breeds,
    height,
    heightUnits,
    verificationStatus,
  } = horse;

  const navigate = useNavigate();
  const {currentVisitorType} = useVisitorTypeService();

  const redirectToBuildHorse = useCallback(
    () => navigate(`${PREFIX_VISITOR_TYPE[currentVisitorType]}/build-a-horse/${horse.id}`),
    [currentVisitorType, horse.id, navigate]
  );
  const redirectToHorseProfile = useCallback(
    () => navigate(`${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${horse.id}`),
    [currentVisitorType, horse.id, navigate]
  );

  const onAddFavoriteHorse = useCallback(async () => {
    await addFavoriteHorse(horse.id);
  }, [horse.id, addFavoriteHorse]);

  const onDeleteFavoriteHorse = useCallback(async () => {
    await deleteFavoriteHorse(horse.id);
  }, [horse.id, deleteFavoriteHorse]);

  const horseOverview = {
    breed: dictionaryToString(breeds, 'Breeds', StringDictionaryErrorType.Full),
    gender,
    dateOfBirth,
    avatar,
    name,
    verificationStatus,
  };

  const color = R.head(colors);

  return (
    <Root>
      <HorseOverview horse={horseOverview} onClickHandler={redirectToHorseProfile} />
      <CharacteristicsWrapper>
        <Characteristics
          height={height}
          heightUnit={heightUnits}
          color={color?.name}
          temperament={abilities.temperament}
          gait={abilities.gait}
          speed={abilities.speed}
          commercialTypes={commercialTypes}
        />
      </CharacteristicsWrapper>
      <ButtonsWrapper className="d-flex flex-column align-items-center justify-content-center">
        {gender !== Gender.Gelding && (
          <ProfileButton className="w-100" size="small" variant="outlined" onClick={redirectToBuildHorse}>
            Build with this horse
          </ProfileButton>
        )}
        <FavoriteHorseButtons
          isOwnFavorite={horse.isFavorite}
          addFavoriteHorseHandler={onAddFavoriteHorse}
          deleteFavoriteHorseHandler={onDeleteFavoriteHorse}
        />
      </ButtonsWrapper>
    </Root>
  );
}

const Connected = withFavoriteHorseActions(memo(PotentialPartner));

export default withDynamicModules(Connected, getWithFavoriteHorseActionsModules());
